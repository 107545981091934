@import 'npm:flexslider/flexslider.css';

#story-people {
	position: relative;
	overflow: hidden;
	padding-bottom: 40px;

	.flex-control-nav {
		position: absolute;
		bottom: 0;
	}

	.flex-control-paging li a {
		background: rgba(240, 240, 240, 0.15);
		width: 9px;
		height: 9px;
		border: 2px solid transparent;
		transition: all 0.2s ease;
	}

	&.rose-nav .flex-control-paging li a.flex-active {
		background-color: #ff3366;
		border-color: #ff3366;
	}
}

.story-person-title {
	font-size: 0.9em;
	line-height: 1;
	font-weight: 700;
}
